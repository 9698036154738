.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;

  @media screen and (min-width: 2000px) {
    margin-top: 4rem;
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

.app__testimonial-btns {
  flex-direction: row;
  margin-top: 1rem;
  width: 100%;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--white-color);

    margin: 1rem;
    transition: all 0.3s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
      color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 100px;
      height: 100px;

      svg {
        width: 45px;
        height: 45px;
      }
    }
  }
}

.app__smallprofile {
  flex-direction: column;
  align-items: center;
  display: none;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
  @media screen and (max-width: 425px) {
    display: flex;
  }
}
